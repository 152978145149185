@import url('https://fonts.googleapis.com/css2?family=Inika:wght@400;700&family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Fleur+De+Leah&display=swap');

:root {
  --max-width: 40rem;
  --font-main: "Montserrat", sans-serif;
  --font-secondary: "Inter", sans-serif;

  --foreground: #F5F2EF;
  /* --foreground: #000; */
  /* --background: url('assets/images/POZADINASVEGAsamo.png');
  --background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url('assets/images/POZADINASVEGAsamo.png'); */
  --background:#000;
  --secondary-color: #7f552b;
  --wash-color: #0154B0;
}

html{scroll-behavior:smooth}

body {
  margin: 0;
  padding: 0;
  background: var(--background);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-size: 10px;
  font-family: var(--font-main);
  color: var(--foreground);
}

.pozadina-hrana {
  background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url('assets/images/POZADINASVEGAhrana.png') !important;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
}

.pozadina-pice {
  background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url('assets/images/POZADINASVEGApice.png') !important;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
}

.sinceText {
  font-family: 'Fleur De Leah';
  font-size: xx-large;
}

.slikaNaMeni::before {
  background: radial-gradient(24.07% 24.07% at 50% 50%, #8E5E24 0%, rgba(0, 0, 0, 0) 100%);
  content: "";
  position: absolute;
  width: 570px;
  height: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container {
  max-width: var(--max-width);
  margin: 0px auto;
  width: 100%;
  position: relative;
}

.image-left {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 6rem;
}

.image-left img {
  height: inherit;
}

.image-right {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 6rem;
  transform: scaleX(-1);
}

.image-right img {
  height: inherit;
}

.image-logo {
  width: fit-content;
  margin: 0rem auto 0px;
  height: 7rem;
  padding-top: 1rem;
}

.image-logo img {
  height: inherit;
}

.title-flex {
  display: flex;
  justify-content: space-between;
  padding: 1.4rem;
  font-size: 2em;
  align-items: center;
  font-weight: bold;
}

.color {
  color: var(--secondary-color);
}

.home-buttons {
  padding: 2rem 1rem 0rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.home-button {
  height: 4rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(79, 77, 77, 0.5);
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-family: var(--secondary-font);
  font-weight: bold;
}

.blue-color {
  border: 1px solid var(--wash-color);
  font-style: italic;
}

.akcija {
  margin: 3rem 1rem 1rem;
}

.created-by {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 1.6em;
}

.created-by a {
  color: white;
}

.slider-wrap {
  width: 100%;
  background: var(--secondary-color);
  position: relative;
  height: 4rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.big-slider-image {
  position: absolute;
  top: -2rem;
  /* left: 50%; */
  width: 8rem;
  height: 8rem;
  background: var(--secondary-color);
  /* transform: translateX(-50%); */
  border-radius: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.left-slider-image {
  position: absolute;
  top: -1rem;
  /* left: 29%; */
  width: 6rem;
  height: 6rem;
  background: var(--secondary-color);
  /* transform: translateX(-50%); */
  border-radius: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
}

.right-slider-image {
  position: absolute;
  top: -1rem;
  /* right: 29%; */
  width: 6rem;
  height: 6rem;
  background: var(--secondary-color);
  /* transform: translateX(50%); */
  border-radius: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
}

.big-slider-image img {
  width: 7.5rem;
  height: 7.5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 20rem;
}

.left-slider-image img, .right-slider-image img {
  width: 5.5rem;
  height: 5.5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 20rem;
}

.menu-main-title {
  background: var(--secondary-color);
  display: inline-block;
  padding: 0.5rem 3rem;
  border-radius: 14px;
  font-size: 1.4em;
}

.main-menu {
  position: relative;
}

.left-main-menu {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  z-index: 10;
  position: relative;
}

.koletovaBoja {
  color: #e18328;
  font-weight: 800;
  font-size: 1.1em;
}

.right-main-menu {
  position: absolute;
  top: 8rem;
  right: -2rem;
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.right-main-menu-relative {
  position: relative;
  width: 100%;
}

.right-menu-image {
  width: 10rem;
  height: 10rem;
  border-radius: 100px;
  position: absolute;
  right: 0;
  border: 2px solid var(--secondary-color);
}

.top1 {
  top:0;
  z-index: 0;
}

.top2 {
  top:8rem;
  width: 13rem;
  height: 13rem;
  z-index: 1;
}

.top3 {
  top:18rem;
  z-index: 0;
}

.right-menu-image img {
  width: inherit;
  height: inherit;
  aspect-ratio: 1;
  border-radius: 100px;
  object-fit: cover;
  filter: brightness(0.35) contrast(1.1);
}

.menu-main-title {
  margin-bottom: 1rem;
}

.menu-main-title h2 {
  margin: 0;
}

.menu-card {
  font-size: 1.3em;
  margin-bottom: 0.7rem;
}

.menu-card h3 {
  margin: 0;
}

.menu-card p {
  margin: 0;
}

.flex-item-price{
  display: flex;
  justify-content: space-between;
}

.admin-buttons {
  display: flex;
  flex-direction: row;
}

.admin-button {
  width: 50%;
  text-align: center;
  color: white;
  height: 3.2rem;
  font-size: 2rem;
}

.admin-title {
  text-align: center;
  font-size: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.admin-form{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-form-title {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.admin-submit {
  font-size: 2rem;
  background: #1c6e1c;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}

.admin-reset {
  font-size: 2rem;
  background: #8a9306;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}

.admin-add-category {
  padding: 0rem 2rem;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.admin-red {
  background: #871a1a;
}

.admin-green {
  background: #1c6e1c;
}

.item-select {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  padding: 0.5rem 0.5rem;
  background: var(--secondary-color);
  margin-bottom: 1.5rem;
}
.item-select ul{
  column-gap: 0.5rem;
}
.item-select a {
  width: 20%;
}
.item-select-one {
  width: 100%;
  border-radius: 100%;
}

.item-select-one img {
  width: 100%;
  border-radius: inherit;
  aspect-ratio: 1;
  object-fit: cover;
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
  font-size: 2.3em;
  padding: 0rem 1rem 1rem 0rem;
}

.breadcrumbs a {
  color: white;
}

.pranjevelikiflex {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 2rem;
}

.pranjeflex {
  display: flex;
}

.pranjeauto {
  width: 7rem;
  height: 7rem;
  object-fit: contain;
  border-radius: 100%;
  border: 2px solid #0154B0;
}

.pranjenaziv {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 2.4em;
  padding-left: 1rem;
  text-align: left;
}

.newLogoDiv img{
  width: 7rem;
  margin: 1rem auto 0px;
  display: block;
}

.newCafeName {
  font-size: 3em;
  text-align: center;
  font-weight: 600; 
  font-family: "Inika", serif;
}

.newCafeNameColor {
  font-size: 3em;
  text-align: center;
  font-weight: 600;
  color: #e18328; 
  font-family: "Inika", serif;
}

.newIconText {
  display: flex;
  align-items: center;
  font-size: 1.7em;
}

.newIconsWrap {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  margin: 0rem 1.4rem;
}

.newLine1 {
  width: 28%;
  border: 1px solid #E18328;
  height: 0;
  position: absolute;
  top: 2rem;
  left: 1rem;
}

.newLine2 {
  width: 28%;
  border: 1px solid #E18328;
  height: 0;
  position: absolute;
  top: 4rem;
  left: 1rem;
}

.newLine3 {
  height: 10rem;
  border: 1px solid #E18328;
  position: absolute;
  top: 1rem;
  left: 2rem;
}

.newLine4 {
  width: 28%;
  border: 1px solid #E18328;
  height: 0;
  position: absolute;
  top: 2rem;
  right: 1rem;
}

.newLine5 {
  width: 28%;
  border: 1px solid #E18328;
  height: 0;
  position: absolute;
  top: 4rem;
  right: 1rem;
}

.newLine6 {
  height: 10rem;
  border: 1px solid #E18328;
  position: absolute;
  top: 1rem;
  right: 2rem;
}